exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-band-1-js": () => import("./../../../src/pages/products/band-1.js" /* webpackChunkName: "component---src-pages-products-band-1-js" */),
  "component---src-pages-products-band-wireless-js": () => import("./../../../src/pages/products/band-wireless.js" /* webpackChunkName: "component---src-pages-products-band-wireless-js" */),
  "component---src-pages-products-circuit-units-js": () => import("./../../../src/pages/products/circuit-units.js" /* webpackChunkName: "component---src-pages-products-circuit-units-js" */),
  "component---src-pages-products-duo-1-js": () => import("./../../../src/pages/products/duo-1.js" /* webpackChunkName: "component---src-pages-products-duo-1-js" */),
  "component---src-pages-products-duo-2-js": () => import("./../../../src/pages/products/duo-2.js" /* webpackChunkName: "component---src-pages-products-duo-2-js" */),
  "component---src-pages-products-duo-wireless-js": () => import("./../../../src/pages/products/duo-wireless.js" /* webpackChunkName: "component---src-pages-products-duo-wireless-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-solo-js": () => import("./../../../src/pages/products/solo.js" /* webpackChunkName: "component---src-pages-products-solo-js" */),
  "component---src-pages-products-wristband-js": () => import("./../../../src/pages/products/wristband.js" /* webpackChunkName: "component---src-pages-products-wristband-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-usecases-js": () => import("./../../../src/pages/usecases.js" /* webpackChunkName: "component---src-pages-usecases-js" */)
}

